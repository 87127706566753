@import 'constant';
@import 'base';
@import 'need-create';
@import 'form-autocomplete';

@mixin need-create-giid {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__label {
    @extend .need-create__step__label;
  }

  &__errors {
    @extend .error;
    display: flex;
    flex-direction: column;
    margin: 1em;
  }

  &__visibility, &__reason, &__client, &__title {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;

    &__label {
      font-weight: bold;
      margin: 1em 0;
    }

    > select {
      color: $link-color;
      display: flex;
      text-align: center;
      width: 100%;
      font-size: 1em;
      font-weight: bold;
      padding: .5em;
      background-color: $bg-main-color;
    }

    > input {
      background-color: $bg-gray;
      cursor: not-allowed;
    }

    &__list {
      @include form-autocomplete();
    }
  }

  &__client__label {
    &:after {
      content: ' (*)';
    }
  }

  &__loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      @extend .fa-spin;
      font-size: 5em;
    }
  }

  &__existed {
    margin: 1em auto;
    font-weight: bold;
    font-size: 1.2em;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $bg-btn;
    color: $main-color;
    margin: .5em 0;
    padding: 1em;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.8em;

    &__client {
      color: $green;
    }
  }

  &__nextPage {
    @extend .btn;
    margin-bottom: 2em;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin: 1em;
  }

  &__action {
    @extend .btn;
    display: flex;
    align-items: center;
    font-weight: bolder;
    padding: .5em 2em;
    margin: 0 .5em;
  }
}

.need-create-giid {
  @include need-create-giid;
}
