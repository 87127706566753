@import "constant";

@mixin profile-create {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1em;

  &.finished {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__summary {
    display: flex;
    color: $main-color;
    flex-direction: column;

    &__description, &__job, &__skills {
      flex: 1;
      display: flex;
      margin-bottom: 0.5em;

      > svg {
        margin: 0 1em;
        cursor: pointer;
      }
    }

    &__value {
      flex: 1;
      display: flex;
    }

    &__edit {
      display: flex;
      color: $main-color;
      width: 2em;
      border: none;
      background: none;
      margin-right: .5em;
      cursor: pointer;
    }
  }

  &__title {
    font-size: 1.2em;
    font-weight: bold;
    margin: 1em 0;
  }

  &__step {
    flex: 1;
    display: flex;

    &__label {
      font-weight: bolder;
      margin: 1em 0;
    }
  }

  &__loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      @extend .fa-spin;
      font-size: 5em;
    }
  }

  &__errors {
    @extend .error;
    display: flex;
    flex-direction: column;
    margin: 1em;
  }

  &__continue {
    @extend .btn;
    margin-bottom: 2em;
  }

  &__isCreated {
    color: $success;
    font-weight: bolder;
    font-size: 1.2em;
    padding: 1em;
    border-radius: 1em;
    margin: 1em;
  }

  &__home {
    @extend .btn;
    margin: 1em;
    font-weight: bolder;
    padding: 1em;
  }
}

.profile-create {
  @include profile-create;
}
