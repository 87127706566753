@mixin offer-teaser {
  margin: 1em;
  display: flex;
  justify-content: space-between;
  background-color: $blue-medium;
  border-radius: 10px;
  cursor: pointer;
  width: calc(100% - 2em);
  font-size: 1em;

  &.selected {
    border: solid 3px $blue-dark;
  }

  &__info {
    &__name {
      font-size: 1.1em;
      margin: 0.3em 1em;
      color: $green;
      text-align: left;
    }

    &__company {
      font-size: 0.85em;
      margin: 0.5em 2em 1.5em 2em;
    }
  }

  &__action-icon {
    display: flex;
    color: dodgerblue;
    margin: auto 1em;
  }

  &__selected-icon {
    display: flex;
    margin: auto 1em;
    color: $green;
    font-size: 1.5em;
  }
}

.offer-teaser {
  @include offer-teaser;
}
