@import 'constant';

body {
  min-height: 100%;
}

#root {
  min-height: 100%;
}

.app {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.app__screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

header {
  color: $black;
}

footer {
  width: 100%;
  background-color: $black;
  color: $white;
  text-align: center;
  font-size: 0.8rem;
}
