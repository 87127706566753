/***********************
        COLOR
 **********************/
$white: #ffffff;
$black: #000000;
$blue-light: #eef8ff;
$blue-medium: #cae3ff;
$blue-dark: #0091ff;
$gray: #d0d0d0;
$warning : #fdb25d;
$green : #4ba64b;
$soft-green : #d0f8d0;
$light-blue-background : #F0F8FFFF;

$main-color: #06347A;
$font-color: #5e5e5e;
$hover-color: #F6F6F6;
$link-color: $blue-dark;
$btn-color: $blue-dark;
$danger: #bb2121;
$success: $green;
$average: #EA620BFF;

$bg-btn: $blue-medium;
$bg-btn-diabled: $gray;
$bg-main-color: #e4f0ff;
$bg-danger: #ecb8b8;
$bg-gray: #ececec;
$bg-green: #a0e09c;

$progress-color: #5e5e5e;


/***********************
        MAX-WIDTH
 **********************/
$min-width-smartphone: 340px;
$max-width-smartphone: 450px;
$min-width-tablet: 641px;
$min-width-desktop: 1001px;


