@mixin spin() {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

@mixin form-entry() {
  $self: &;
  display: flex;
  flex-direction: column;
  margin: 1em 0;
  position: relative;
  max-width: 600px;

  &__control {
    display: flex;
    position: relative;
    width: 100%;

    &__right {
      display: flex;
      align-items: center;
      color: $main-color;
      padding: 0 .8em;
      border: $main-color;
    }

    &__right {
      border-left: none;
    }

    input {
      height: calc(100% - .4em - 2px);
      border: solid 1px $main-color;
      padding: .2em .5em;
      min-height: 2em;
      width: calc(100% - 1em);
      font-size: 1rem;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none; /* Firefox */
        margin: 0;
      }

      &[type=number] {
        -moz-appearance: textfield; /* Firefox */
      }

      &::-webkit-input-placeholder /* Edge */
      {
        color: $font-color;
      }

      &:-ms-input-placeholder /* Internet Explorer 10-11 */
      {
        color: $font-color;
      }

      &::placeholder {
        color: $font-color;
      }

      &:focus-visible {
        outline: 1px solid $main-color;
        outline-offset: 2px;
      }
    }

    .disabled &,
    &.disabled {
      color: $font-color;
      border-color: $bg-gray;
      cursor: not-allowed;

      input {
        color: $green;
        font-weight: bold;
        border: 1px solid $font-color;
        cursor: not-allowed;
      }

      &__right {
        border-color: $font-color;
        color: $font-color;
      }
    }
  }

  &__loading {
    @include spin;
    position: absolute;
    right: -1.5em;
  }

  &.loading &__loading, {
    color: $black;
    height: 100%;
    display: flex;
    align-items: center;

    > svg {
      @include spin;
    }
  }
}


@mixin form-autocomplete() {
  @include form-entry();

  &.disabled input {
    border-right: none;
  }

  &.disabled &__control__right {
    cursor: pointer;
    border: solid 1px $main-color;
    color: $main-color;
  }

  &.loading &__loading, {
    color: $black;
    height: 100%;
    display: flex;
    align-items: center;

    > svg {
      @include spin;
    }
  }

  &__autocomplete {
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 5px 0 $black;
    padding: .5em;
    position: absolute;
    top: calc(100% + 10px);
    width: calc(100% - 1em);
    background-color: $white;
    z-index: 100;

    &__emptyMessage {
      font-size: .8em;
      color: $font-color;
      padding: .5em .3em;
    }

    &__option {
      white-space: pre-line;
      padding: .5em .3em;
      color: $main-color;
      font-weight: bold;

      &:not(:last-child) {
        border-bottom: solid 1px $main-color;
      }

      &.clickable {
        cursor: pointer;

        &:hover,
        &:focus-visible {
          background-color: $main-color;
          color: $white;
          cursor: pointer;
        }
      }
    }
  }
}

.form-autocomplete {
  @include form-autocomplete()
}
