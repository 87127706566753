@import 'base';
@import 'need-create';

@mixin need-create-skills {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__label {
    @extend .need-create__step__label;
  }

  &__list {
    flex: 1;
    padding-left: 1em;
  }

  &__item {
    text-transform: uppercase;
    font-weight: bold;
    margin: 1.5em 0;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    background: none;
    font-size: .9em;

    &__label {
      color: $main-color;
    }

    > svg {
      display: flex;
      margin: auto 1em;
    }
  }

  &__block__title {
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
    font-weight: bold;
    color: $black;
    cursor: pointer;
    width: 100%;
    background: none;
    font-size: 1em;

    > svg {
      display: flex;
      margin: auto 1.1em;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin: 1em;
  }

  &__action {
    @extend .btn;
    font-weight: bolder;
    padding: .5em 2em;
    margin: 0 .5em;
  }
}

.need-create-skills {
  @include need-create-skills;
}
