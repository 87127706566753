@import 'constant';

@mixin login {
  display: flex;
  flex-direction: column;
  padding: 1em;

  &__submit {
    @extend .btn;
    margin-top: 1em;
  }
  
  &__header {
    color: $black;
    margin: 1em;

    img {
      max-height: 150px;
    }
  }
}

.login {
  @include login;
}
