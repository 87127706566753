@import 'base';
@import 'profile-create';

@mixin profile-create-job {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__errors {
    @extend .error;
    display: flex;
    flex-direction: column;
    margin: 1em;
  }

  &__loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      @extend .fa-spin;
      font-size: 5em;
    }
  }

  &__label > p {
    @extend .profile-create__step__label;
    font-size: inherit;
    color: $black;
    font-weight: bold;

    > strong {
      color: $warning;
    }
  }

  &__categories {
    padding-left: 1em;
  }

  &__category {
    &__label {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0.8em 0;
    }

    &__types {
      padding: 0 0 0 1em;
    }
  }

  &__type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    > svg {
      margin: 0.5em 1em;
      cursor: pointer;
    }

    &__label {
      margin-bottom: 0.5em;
      color: $main-color;
      cursor: pointer;
      max-width: 40%;
      overflow-x: hidden;
    }
  }

  &__level {
    &:after {
      content: ' / '
    }

    &.Débutant {
      color: $main-color;
    }

    &.Polyvalent {
      color: $btn-color;
    }

    &.Principal {
      color: $success;

      &:after {
        content: ''
      }
    }

    &__label {
      display: flex;
      justify-content: flex-end;
      margin: 1em 0;
      min-width: 50%;

      > div {
        font-size: 0.8em;
        margin: 0 .1em;
      }
    }

    &__range {
      min-width: 50%;
      border: none;

      &::-webkit-slider-runnable-track, &::-moz-range-track {
        background: $gray;
        outline: none;
        box-sizing: border-box;
        cursor: pointer;
        height: 5px;
      }

      &::-webkit-slider-thumb, &::-moz-range-thumb {
        width: 1em;
        height: 1em;
        border-radius: 20px;
        background: $font-color;
        border-color: $font-color;
        cursor: pointer;
      }

      &.Débutant {
        &::-webkit-slider-runnable-track, &::-moz-range-track {
          background: linear-gradient(to right, $main-color 0 33%, $gray 33% 100%);
        }

        &::-webkit-slider-thumb, &::-moz-range-thumb {
          background: $main-color;
          border-color: $main-color;
        }
      }

      &.Polyvalent {
        &::-webkit-slider-runnable-track, &::-moz-range-track {
          background: linear-gradient(to right, $btn-color 0 66%, $gray 66% 100%);
        }

        &::-webkit-slider-thumb, &::-moz-range-thumb {
          background: $btn-color;
          border-color: $btn-color;
        }
      }

      &.Principal {
        &::-webkit-slider-runnable-track, &::-moz-range-track {
          background: $success;
        }

        &::-webkit-slider-thumb, &::-moz-range-thumb {
          background: $success;
          border-color: $success;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin: 1em;
  }

  &__action {
    @extend .btn;
    padding: 0.5em 2em;
    font-weight: bolder;
    margin: 0 .5em;

    &.disabled {
      opacity: 0.4;
      padding: 0.5em 2em;
      cursor: not-allowed;
    }

    &-add {
      @extend .btn;
      display: flex;
      width: auto;
    }

    &-cancel {
      @extend .btn;
      display: flex;
      width: auto;
      background-color: $danger;
      color: $white;
    }
  }
}

.profile-create-job {
  @include profile-create-job();
}
