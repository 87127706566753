@import 'constant';

body {
  margin: 0;
  font-family: linotte-semibold, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $font-color
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

p {
  font-size: 13px;
  @media screen and (min-width: $min-width-tablet) {
    font-size: 16px;
  }
  @media screen and (min-width: $min-width-desktop) {
    font-size: 18px;
  }
}

.loading > svg {
  animation: spinner 1.2s linear infinite;
}

.loading-big {
  font-size: 3em;
  color: $main-color;
  text-align: center;
}

a {
  text-decoration: none;
}

button {
  border: none;
  font-weight: bold;
}

.screen-override {
  position: absolute;
  top: 60px;
  right: 0;
  width: 100vw;
  height: calc(100vh - 60px);
  background: $white;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  z-index: 2;

  .close {
    svg {
      cursor: pointer;

      &:hover {
        color: gray;
      }
    }
  }
}

.breadcrumb {
  display: flex;
  flex: 1;
  padding: 0;
  justify-content: center;
  color: $main-color;
}

.breadcrumb li {
  list-style: none;
  margin-left: 1em;
}

.ul-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.li-nav {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  flex-wrap: wrap;

  > a {
    text-decoration: none;
    margin-right: 1em;

    &:hover {
      color: $hover-color;
      background-color: $bg-gray;
    }
  }

  &.close {
    justify-content: flex-end;
    padding-right: 2em;
    padding-top: 0.5em;
    cursor: initial;

    &:hover {
      color: initial;
      background-color: initial;
    }
  }

}

.steps {
  display: flex;
  justify-content: center;
  padding-inline: 1em;

  .step {
    padding: 0.5em;
    background-color: $gray;
    border-radius: 50%;
    color: $white;

    &.active {
      background-color: $main-color;
    }
  }

  .inter-step {
    flex: 1;
    display: flex;
    align-items: center;

    > div {
      height: 0.1em;
      width: 100%;
      background-color: $gray;


    }

    &.active > div {
      background-color: $main-color;
    }
  }
}

.screen-navbar {
  display: flex;
  justify-content: center;
  padding: 1em;
}

@mixin progressbar-x {
  @for $i from 1 through 100 {
    .progressbar-#{$i} {
      $percentage: unquote("%");
      background: linear-gradient(0.25turn, $main-color #{$i}$percentage, $white 0);

      .progressbar-index {
        background: linear-gradient(0.25turn, $white #{$i}$percentage, $main-color 0);
        background-clip: text;
        color: transparent;
      }
    }
  }
}

@mixin spin() {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-spin {
  @include spin;
}

@include progressbar-x;

.progressbar {
  position: relative;
  border: solid 1px $main-color;
  margin: 1em;
  display: flex;
  height: 2em;
}

.progressbar-index {
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;
}

.error {
  font-size: 0.8em;
  color: $danger
}
