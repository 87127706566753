@mixin request {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  background-color: aliceblue;

  &__error {
    @extend .error;
    display: flex;
    margin: 2em auto;
    font-weight: bolder;
  }

  &__info {
    &__noProfiles, &__already-answer {
      font-weight: bolder;
      display: flex;
      justify-content: center;
      margin: 0.5em 1em;
      padding: 1em;
      white-space: pre-line;
    }

    &__already-answer {
      color: $warning;
    }
  }

  &__confirm {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;

    &__label, &__label-giid {
      font-size: 1.2em;
      font-weight: bolder;
      margin: 1em 0.5em;
    }

    &__label-giid {
      color: $danger;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;

    &__request__back {
      @extend .btn-disabled;
      display: flex;
      justify-content: center;
      margin: 0.5em 1em;
      padding: 1em;
      white-space: pre-line;
      color: $btn-color;
      font-style: normal;
      font-size: 1em;
      font-weight: bolder;
      background-color: $blue-medium;
      cursor: pointer;

      &-icon {
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  &__action {
    &__response {
      color: #ffd700;
      font-size: 1.2em;
      margin-left: 1em;
    }

    &__message {
      color: #daa520;
      font-size: 1.2em;
    }

    &__delete {
      color: #8b0000;
      font-size: 1.2em;
      margin-left: 1em;
    }

    &__mines {
      @extend .btn;
      display: flex;
      justify-content: center;
      margin: 0.5em 1em;
      padding: 1em;
      font-weight: bolder;

      &.back {
        align-items: center;

        > svg {
          margin-right: 2em;
        }
      }

      &__disabled {
        @extend .btn;
        cursor: not-allowed;
        display: flex;
        justify-content: center;
        margin: 0.5em 1em;
        padding: 1em;
        font-weight: bolder;
        opacity: 0.4;
      }
    }

    &__others {
      font-weight: bolder;
      @extend .btn;
      display: flex;
      justify-content: center;
      margin: 0.5em 1em;
      padding: 1em;
      white-space: pre-line;

      &__disabled {
        @extend .btn;
        display: flex;
        justify-content: center;
        cursor: not-allowed;
        margin: 0.5em 1em;
        padding: 1em;
        font-weight: bolder;
        opacity: 0.4;
      }
    }

    &__do-proposal {
      font-weight: bolder;
      @extend .btn;
      display: flex;
      justify-content: center;
      margin: 0.5em 1em;
      padding: 1em;
      white-space: pre-line;
    }

    &__noprofilesforgiid {
      @extend .btn;
      font-weight: bolder;
      background-color: $warning;
      color:$black;
      display: flex;
      justify-content: center;
      margin: 0.5em 1em;
      padding: 1em;
      white-space: pre-line;
    }

    &__back {
      @extend .btn;
      font-weight: bolder;
      margin: 1em;
      padding: 1em 0;

       > svg {
         margin-right: 2em;
       }
    }

    &__new-profile {
      @extend .btn;
      font-weight: bolder;
      background-color: $green;
      color: $white;
      margin: 1em;
      padding: 1em 0;
    }

    &__confirm-delete {
      @extend .btn;
      font-weight: bolder;
      display: flex;
      justify-content: center;
      margin: 0.5em 1em;
      padding: 1em;

      > svg {
        color: #8b0000;
        font-size: 1.2em;
        margin-left: 2em;
      }

      &__giid {
        @extend .btn-danger;
        font-weight: bolder;
        display: flex;
        justify-content: space-between;
        margin: 0.5em 1em;
        padding: 1em;
      }
    }

    &__cancel-delete {
      @extend .btn;
      display: flex;
      justify-content: left;
      margin: .5em 1em;
      padding: 1em;
      font-weight: bold;

      &__giid {
        @extend .btn-disabled;
        background-color: $bg-btn !important;
        color: $main-color !important;
        display: flex;
        justify-content: left;
        margin: 0.5em 1em;
        padding: 1em;
        font-weight: bold;
      }
    }
  }

  &__back {
    @extend .btn;
    margin: 0.5em 1em;
    font-weight: bolder;
  }

  &__loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      @extend .fa-spin;
      font-size: 5em;
    }
  }
}

.request {
  @include request;
}
