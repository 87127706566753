@import "need-create";
@import "form";

@mixin need-create-entry {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__label {
    @extend .need-create__step__label;

    &:after {
      content: ' : (*)'
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 1em 0;
    padding: 1em 0;
    border-top: solid 1px $gray;

    @media (max-width: $max-width-smartphone) {
      flex-wrap: wrap;
    }

    &__schedule {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 1em 0;
      max-width: 90vw;

      @media (max-width: $min-width-smartphone) {
        flex-direction: column;

        > * {
          margin: .5em 0;
        }
      }

      &__start, &__end {
        display: flex;
        justify-content: center;
        flex: 1;
        align-items: center;
        margin: .5em;
        max-width: 200px;

        > select {
          color: $link-color;
          display: flex;
          text-align: center;
          width: 100%;
          font-size: 1em;
          font-weight: bold;
          padding: .5em;
          background-color: $bg-main-color;
        }

        > span {
          font-size: 1.2em;
          font-weight: bold;
          margin-right: .5em;

          @media (max-width: $max-width-smartphone) {
            margin-right: 1em;
          }
        }
      }
    }

    &__days {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 1em;
      max-width: 90vw;

      @media (max-width: $min-width-smartphone) {
        justify-content: normal;
      }

      &.withError {
        background-color: $bg-danger;
      }

      &__item {
        display: flex;
        align-items: center;
        margin: 1em .5em;

        @media (max-width: $max-width-smartphone) {
          flex-direction: column;
          margin: .5em;
          font-weight: bolder;
        }

        > span {
          color: $main-color;
        }

        > input[type="checkbox"] {
          cursor: pointer;
          width: 1.2em;
        }
      }
    }

    &__entry {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 1em;
      max-width: 70vw;

      @media (max-width: $max-width-smartphone) {
        margin-top: 1em;
      }

      > label {
        color: $black;
        margin-bottom: .5em;

        &:after {
          content: ' : (*) ';
        }
      }

      &.withError {
        > label {
          color: $danger;
        }

        > input {
          border: solid 2px $danger;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin: 1em;
  }

  &__action {
    @extend .btn;
    font-weight: bolder;
    padding: .5em 2em;
    margin: 0 .5em;

    &.disabled {
      opacity: 0.4;
      padding: 0.5em 2em;
      cursor: not-allowed;
    }
  }

  &__error {
    @extend .error;
    display: flex;
    margin: 0 auto;
    font-size: 1.3em;
  }
}

.need-create-entry {
  @include need-create-entry;
}
