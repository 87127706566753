@mixin offer-details {
  margin: 1em;

  &__info {
    border-left: solid 5px $blue-dark;
    padding-left: 0.5em;

    &__name {
      color: $black;
      font-weight: bolder;
      font-size: 1.2em;
    }

    &__company, &__skills {
      color: $blue-dark;
      font-weight: bolder;
      margin: 0.5em 0;
      font-size: 0.9em;
    }
  }

  &__action {
    &__back {
      @extend .btn;
      display: flex;
      align-items: center;
      font-weight: bolder;
      margin: 1em 0;
      padding: 1em;
      width: 95%;

      > svg {
        margin-left: 1em;
        margin-right: 5em;
      }
    }

    &__accept, &__decline {
      @extend .btn;
      display: flex;
      align-self: flex-start;
      color: $white;
      font-weight: bolder;
      background-color: $green;
      padding: 1em;
    }

    &__decline {
      background-color: $danger;
    }
  }

  &__already-accepted {
    @extend .btn;
    display: flex;
    align-self: flex-start;
    color: $white;
    font-weight: bolder;
    background-color: $green;
    cursor: not-allowed;
    opacity: 0.3;
    padding: 1em;
  }

  &__waiting {
    display: flex;
    justify-content: center;
    margin: 1.2em;
    font-weight: bolder;
    color: $black;
  }

  &__actions_ {
    display: flex;
    justify-content: space-between;
    margin: 1em .5em;
  }

  &__expand {
    display: flex;
    flex-direction: column;
    padding: .5em;
    background-color: $white;
    z-index: 10;
    box-shadow: 0 3px 6px $black;
    width: 200px;
    position: relative;

    > svg {
      background-color: $danger;
      color: $white;
      padding: .2em .4em;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }

    &__wrapper {
      margin-top: 1.5em;
    }

    &__item {
      padding: .5em;
      overflow-x: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      color: $danger;
      font-weight: bold;
      background: none;
      width: 100%;
      font-size: 1em;
      text-align: left;

      &:hover {
        color: $white;
        font-weight: bold;
        background-color: $danger;
      }
    }
  }

  &__error {
    display: flex;
    justify-content: center;
    margin: 1em auto;
    text-align: center;
    font-size: 1em;
    color: $danger;
  }
}

.offer-details {
 @include offer-details;
}
