@import "need-create";
@import "form";

@mixin need-create-comment {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__label {
    @extend .need-create__step__label;
  }

  &__value {
    flex: 1;
    display: flex;
    flex-direction: column;

    > textarea {
      min-height: 7em;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin: 1em;
  }

  &__action {
    @extend .btn;
    font-weight: bolder;
    padding: .5em 2em;
    margin: 0 1em;
  }
}

.need-create-comment {
  @include need-create-comment;
}
