@mixin profile-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $light-blue-background;

  &__errorMessage {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $light-blue-background;
  }

  &__errorHead {
    display: flex;
    flex-direction: column;
    margin: 0.5em;
    justify-content: space-around;
    height: 15vh;
  }

  &__errorTitle {
    font-size: 1em;
    font-weight: 700;
    color: $danger;
  }

  &__head {
    margin-top: 0.5em;
    margin-left: 1em;
    margin-right: 1em;
  }

  &__title {
    font-size: 1em;
    font-weight: bolder;
    margin: 1em 0
  }

  &__need {
    color: $blue-dark;
    display: flex;
    align-items: center;
    margin: 1em 0;

    &__icon {
      padding-right: 0.5em;
      font-size: 1em;
    }

    &__resource {
      color: $blue-dark;
    }
  }

  &__info {
    border-left: 4px solid $blue-dark;
    padding: 0 10px;
    margin: 20px 25px;
    font-size: 1em;

    &__head {
      justify-content: space-around;
      margin-bottom: 1.5em;
    }

    &__label {
      font-size: 1em;
      margin-top: 0;
      font-weight: bolder;
    }
  }

  &__skills {
    color: $blue-dark;

    &__map {
      margin-bottom: 1em;

      &:nth-child(1) {
        margin-bottom: 1.7em;
      }
    }
  }

  &__errorActions {
    justify-content: flex-end;

    &__back {
      @extend .btn;
      display: flex;
      font-size: 1em;
      margin: .5em;
      padding: 1em 1em;
      align-items: flex-start;
      justify-content: center;
    }

    &__backIcon {
      display: flex;
      justify-content: flex-start;
    }

    &__backReturn {
      margin: auto;
    }
  }

  &__proposed-profile {
    display: flex;
    justify-content: center;
    background-color: $soft-green;
    color: $black;
    font-weight: bolder;
    margin: 0 1em;
    padding: 1em;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    font-weight: bolder;

    &__back, &__proposal, &__revoke {
      @extend .btn;
      display: flex;
      font-size: 1em;
      margin: 0.5em 2em;
      padding: 1em 1em;
      align-items: flex-start;
      justify-content: center;
    }

    &__proposal {
      background-color: $green;
      color: $white;
    }

    &__revoke {
      background-color: $danger;
      color: $white;
    }

    &__backIcon {
      display: flex;
      justify-content: flex-start;
    }

    &__backReturn {
      margin: auto;
    }
  }

  &__error {
    display: flex;
    justify-content: center;
    margin: 1em auto;
    text-align: center;
    font-size: 1em;
    color: $danger;
  }
}

.profile-details {
  @include profile-details;
}

