@import 'constant';
@import 'base';
@import 'profile-create';

@mixin profile-create-description() {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__error {
    @extend .error;
    display: flex;
    flex-direction: column;
    margin: 1em;
  }

  &__label, &__mobility__label, &__contact__label {
    @extend .profile-create__step__label;

    &:after {
      content: ' : '
    }
  }

  &__label {
    margin-bottom: 1em;
    color: $black;
    text-align: center;
  }

  &__mobility, &__contact {
    flex: 1;
    display: flex;
    flex-direction: column;

    > select {
      color: $link-color;
      display: flex;
      text-align: center;
      width: 100%;
      font-size: 1em;
      font-weight: bold;
      padding: .5em;
      background-color: $bg-main-color;
    }
  }

  &__name {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;

    &__label {
      font-weight: bolder;
      margin-bottom: .5em;

      &:after {
        content: ' : (*)';
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin: 1em;
  }

  &__action {
    @extend .btn;
    padding: 0.5em 2em;
    font-weight: bolder;
    margin: 0 .5em;
  }
}

.profile-create-description {
  @include profile-create-description;
}
