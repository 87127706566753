@import "constant";

@mixin need-create {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1em;

  &__summary {
    display: flex;
    color: $main-color;
    flex-direction: column;

    &__giid, &__skills, &__number,
    &__comment, &__job, &__dates, &__entry {
      display: flex;
      margin-bottom: 0.5em;
      flex: 1;

      > svg {
        margin: 0 1em;
        cursor: pointer;
      }
    }

    &__value {
      display: flex;
      flex: 1;
    }

    &__edit {
      display: flex;
      color: $main-color;
      width: 2em;
      border: none;
      background: none;
      margin-right: .5em;
      cursor: pointer;
    }
  }

  &__title {
    font-size: 1.2em;
    font-weight: bold;
    margin: 1em 0;
  }

  &__step {
    display: flex;
    flex: 1;

    &__label {
      margin: 1em 0;
      font-weight: bolder;
      color: $black;
      font-size: 1.1em;
    }
  }

  &__loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      @extend .fa-spin;
      font-size: 5em;
    }
  }

  &__errors {
    @extend .error;
    display: flex;
    flex-direction: column;
    margin: 1em;
  }

  &__confirm {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  &__continue {
    @extend .btn;
    font-weight: bold;
    margin: 2em;
    padding: .5em;
  }

  &__success {
    display: flex;
    margin: 1em;
    color: $success;
  }
}

.need-create {
 @include need-create;
}
