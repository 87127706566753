@mixin proposal-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  background-color: $light-blue-background;

  &__head {
    margin-top: 0.5em;
    margin-left: 1em;
    margin-right: 1em;
  }

  &__title {
    font-size: 1em;
    font-weight: bolder;
    margin: 1em 0
  }

  &__need {
    color: $blue-dark;
    display: flex;
    align-items: center;
    margin: 1em 0;

    &__icon {
      padding-right: 0.5em;
      align-items: center;
    }
  }

  &__demand {
    margin-top: 0.5em;
    font-size: 1em;
  }

  &__name {
    margin-top: 0.5em;
    flex-direction: column;
    flex: 1 1;
    overflow-y: scroll;
    background-color: $light-blue-background;

    &__list {
      @extend .btn;
      display: flex;
      margin: 1em;
      padding: 1em;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      color: $white;
      font-weight: bolder;
      width: 95%;

      &.skills__success{
        background-color: $success;
      }

      &.skills__medium{
        background-color: $average;
      }

      &.skills__none{
        background-color: $danger;
      }
    }
  }

  &__actions {
    justify-content: flex-end;

    &__new-profile, &__back {
      @extend .btn;
      display: flex;
      font-size: 1em;
      font-weight: bolder;
      margin: 1em;
      padding: 1em 1em;
      align-items: flex-start;
      justify-content: center;
    }

    &__new-profile {
      background-color: $green;
      color: $white;
    }

    &__back {
      align-items: center;
      justify-content: center;

      > svg {
        margin-right: 2em;
      }
    }
  }

  &__loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      @extend .fa-spin;
      font-size: 5em;
    }
  }

  &__empty {
    display: flex;
    margin: 2em auto;
    font-weight: bolder;
  }

  &__error {
    @extend .error;
    display: flex;
    margin: 2em auto;
    font-weight: bolder;
  }
}
.proposal-list {
  @include proposal-list;
}
