@import 'constant';
@import 'form';

@mixin askNotificationPermission {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  text-align: center;

  &__loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      @extend .fa-spin;
      font-size: 5em;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 1.2em;
  }

  &__actions {
    display: flex;
    justify-content: space-around;

    > div {
      @extend .btn;
    }
  }

  &__error {
    display: flex;
    justify-content: space-around;
  }
}

.askNotificationPermission {
  @include askNotificationPermission;
}
