@import 'base';
@import 'need-create';

@mixin need-create-job {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__label {
    @extend .need-create__step__label;
  }

  &__errors {
    @extend .error;
    display: flex;
    flex-direction: column;
    margin: 1em;
  }

  &__loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      @extend .fa-spin;
      font-size: 5em;
    }
  }

  &__categories {
    padding: 0 0 0 1em;
  }

  &__category {
    &__label {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0.8em;
      margin-top: 0.8em;
    }

    &__types {
      padding: 0 0 0 1em;
    }
  }

  &__type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    width: 100%;
    font-size: 1em;

    &__label {
      color: $main-color;
      font-weight: bold;
      font-size: .9em;
      cursor: pointer;
    }

    > svg {
      margin: 0.5em 1em;
      cursor: pointer;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin: 2em 0 1em;
  }

  &__action {
    @extend .btn;
    font-weight: bolder;
    padding: .5em 2em;
  }
}

.need-create-job {
  @include need-create-job;
}
