@import "need-create";
@import "form";

@mixin need-create-number {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 90%;

  &__label {
    @extend .need-create__step__label;
  }

  &__value {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
  }

  &__input {
    display: flex;
    align-items: center;
    margin: 3em;

    > svg {
      @extend .btn;
      border-radius: 0.5em 0 0 0.5em;
      padding: 0 1em;
      height: 1.6em;
      margin: 0 -.3em;
      z-index: 20;
    }

    > input {
      flex: 1;
      text-align: center;
      font-weight: bold;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin: 1em;
  }

  &__action {
    @extend .btn;
    font-weight: bolder;
    padding: .5em 2em;
    margin: 0 .5em;
  }
}

.need-create-number {
  @include need-create-number;
}
