@import 'constant';
textarea {
  font-family: linotte-semibold, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  color: $font-color
}

.btn {
  background: $bg-btn;
  color: $btn-color;
  font-size: 1em;
  cursor: pointer;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 0.5em;

  > svg {
    margin-right: 1em;
  }
}
.btn-success{
  font-size: 1em;
  cursor: pointer;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 0.5em;
  background:$success!important;
  color:$white!important;
}

.btn-danger{
  background:$danger!important;
  color:$white!important;
  font-size: 1em;
  cursor: pointer;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 0.5em;

  > svg {
    margin-right: 1em;
  }
}

.btn-disabled {
  background: $bg-btn-diabled;
  font-size: 1em;
  cursor: not-allowed;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 0.5em;

  > svg {
    margin-right: 1em;
  }
}

input {
  padding: 0.2em 0.5em;
  border: 1px solid $gray;
  min-height: 2em;
  font-size: 1em;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    border-radius: 5px;
    padding: 5px 15px;
    border: 1px solid #D0D0D0;
  }

  &[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }
}

select {
  border-radius: 5px;
  padding: 5px 15px;
  border: 1px solid #D0D0D0;
}


.invalid-feedback {
  margin-bottom: 1em;
  background-color: $bg-danger;
  padding: 1em;
  color: $danger;
}

.has-error {
  border-color: $danger;
}
