@import 'base';
@import 'profile-create';

@mixin profile-create-skills {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__label {
    @extend .profile-create__step__label;
  }

  &__list {
    padding: 0 0 0 1em;
  }

  &__item {
    text-transform: uppercase;
    font-weight: bold;
    margin: 1.5em 0;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    background: none;
    font-size: .9em;

    > svg {
      display: flex;
      margin: auto 1em;
    }

    &__label {
      color: $main-color;
    }
  }

  &__continue {
    @extend .btn;
    margin: 1em;
    font-weight: bolder;
  }

  &__block__title {
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
    font-weight: bold;
    color: $black;
    cursor: pointer;
    width: 100%;
    background: none;
    font-size: 1em;

    > svg {
      display: flex;
      margin: auto 1.1em;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin: 1em;
  }

  &__action {
    @extend .btn;
    padding: 0.5em 2em;
    font-weight: bolder;
    margin: 0 .5em;
  }
}

.profile-create-skills {
  @include profile-create-skills;
}
