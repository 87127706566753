@import "need-create";
@import "form";

@mixin need-create-dates {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__datePicker {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  &__label {
    @extend .need-create__step__label;

    &:after {
      content: ' (*)';
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin: 1em;
  }

  &__action {
    @extend .btn;
    padding: 0.5em 2em;
    font-weight: bolder;
    margin: 0 .5em;

    &.disabled {
      opacity: 0.4;
      padding: 0.5em 2em;
      cursor: not-allowed;
    }
  }

  &__error {
    @extend .error;
    display: flex;
    margin: 1em auto;
    font-size: 1.3em;
  }
}

.need-create-dates {
  @include need-create-dates;
}
