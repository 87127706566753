@mixin home {
  display: flex;
  flex-direction: column;
  width: 100vw;
  flex: 1;
  overflow-y: scroll;

  > div + div {
    margin-bottom: 1em;
  }

  &__header {
    img {
      max-height: 7vh;
    }
  }

  &__hello {
    padding-top:0.5em;
    padding-left: 2em;
    font-weight: bold;
    font-size: 1.2em;
    color: $black;
  }

  &__welcome, &__mines, &__others {
    display: flex;
    margin: 0 1em;
    flex: 1;
    border: 1px solid $gray;
    border-radius: 0.5em;

    img {
      padding-left: 0.5em;
      max-height: 80px;
    }

    svg {
      color:$main-color;
      font-size: 1.5em;
      font-weight: lighter;
      padding-right: 0.5em;
    }
  }

  &__mines, &__others {
    background-color: $bg-main-color;
    flex-direction: row;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: .5em 1em;
    color: currentColor;

    > div {
      display: flex;
      flex: 1;
    }

    &__text {
      display: flex;
      flex: 1;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding-left: 1em;
      margin-right: 0.5em;

      > div{
        font-size: 1.1em;
        color: $main-color;
        padding-bottom: 0.5em;
      }
    }
  }

  &__welcome {
    flex-direction: column;
    background-color: $bg-gray;
    font-weight: bold;
    justify-content: center;
    padding-left: 1em;
    padding-top: .3em;

    &__main {
      font-size: 1.2em;
      padding-bottom: 1em;
      color: $main-color;
    }
  }

  &__action-reconnect {
    @extend .btn;
    display: flex;
    justify-content: center;
    font-weight: bolder;
    margin: auto 4em;
    background-color: $warning;
  }

  &__loading {
    @extend .fa-spin;
    display: flex;
    margin: auto;
    font-size: 7em;
    color: $black;
  }

  &__reset {
    @extend .btn;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 0 1em 1em 1em;
    font-weight: bolder;
    font-size: .9em;
    border: none;

    > svg {
      width: 2em;
    }
  }

  &__error, &__success {
    display: flex;
    margin: 0 1em 1em 1em;
    font-weight: bolder;
    color: $danger;
    font-size: .9em;
  }

  &__success {
    color: $success;
  }
}
.home {
  @include home;
}
