@import "constant";

@mixin navbar {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2vh;
  width:100%;
  color:$black;
  border-top: 1px solid $gray;
  align-items: flex-end;

  &__home, &__mine, &__others, &__exit {
    display:flex;
    flex-direction: column;
    text-align: center;
    justify-content:center;
    background-color: $white;
    cursor: pointer;
  }

  &__icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1.5em;
    margin-bottom: 0.2em;

    img {
      height: 2em;
      top: 10px;
      position: relative;
    }
  }
}

.navbar {
  @include navbar;

  a {
    color: currentColor;
  }
}
