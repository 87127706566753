@mixin declined {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  background-color: $light-blue-background;
  padding: 1em;

  &__title > p {
    font-size: 1.2em;
    font-weight: bolder;
    margin: 1em 0;
    text-align: center;

    > strong {
      color: $blue-dark;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    margin: 1em;
    background-color: $danger;
    color: $white;
    z-index: 10;
    box-shadow: 0 3px 6px $black;
    font-weight: bold;
  }
  &__feed {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__actions {
    justify-content: flex-end;

    &__new-profile, &__back {
      @extend .btn;
      display: flex;
      font-size: 1em;
      font-weight: bolder;
      margin: 1em;
      padding: 1em 1em;
      align-items: flex-start;
      justify-content: center;
    }

    &__new-profile {
      background-color: $green;
      color: $white;
    }

    &__back {
      align-items: center;
      justify-content: center;

      > svg {
        margin-left: -3em;
        margin-right: 5em;
      }
    }
  }

  &__loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      @extend .fa-spin;
      font-size: 5em;
    }
  }

  &__empty {
    display: flex;
    margin: 2em auto;
    font-weight: bolder;
  }

  &__error {
    @extend .error;
    display: flex;
    margin: 2em auto;
    font-weight: bolder;
  }
}

.proposal-declined {
  @include declined;
}
