@mixin request-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;

  &__label {
    font-size: 1.2em;
    font-weight: bolder;
    margin: 1em 0.5em;
    color: $btn-color;
    font-style: normal;
  }

  &__resource, &__dates, &__skills, &__entry {
    margin: 1em;
    font-weight: bolder;
    color: $btn-color;

    > * {
      margin: 0 0.5em;
    }
  }

  &__comment {
    color: $btn-color;
    margin: 1em;
    text-align: left;

    &__label {
      font-weight: bolder;
    }

    &__feed {
      text-align: left;
      margin-top: 1em;
      margin-left: 1em;
      font-style: italic;
      font-size: 0.85em;
    }
  }
}

.request-info {
  @include request-info;
}
