@import 'base';
@import 'constant';

@mixin giid-list {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: $light-blue-background;

  &.blank {
    align-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: $white;
    width: 85vw;
    max-width: 560px;
    height: 120px;
    padding: .5em;
    margin: 1em;

    &__count {
      margin: 1em auto;
      padding: 0.5em;
      background-color: $blue-medium;
      border-radius: 0.5em;
      height: 25px;
      width: 150px;
    }

    &__title, &__client, &__period, &__total-needs {
      background-color: $gray;
      width: 65%;
      max-width: 300px;
      height: 10px;
      margin: .5em;
    }

    &__client {
      width: 75%;
      max-width: 400px;
      background-color: $blue-medium;
    }

    &__period {
      margin-left: 1em;
      width: 45%;
      max-width: 200px;
    }

    &__total-needs {
      width: 15%;
      max-width: 100px;
      background-color: $blue-medium;
      margin: 0 2em .5em auto;
    }
  }

  &__demand__response-title {
    color: $black;
    margin: .5em 1em;
    font-size: 1.6em;
    font-weight: bolder;
  }

  &__searchBar {
    display: flex;
    align-items: center;
    margin: 0.5em 1em;
    justify-content: space-evenly;

    input {
      width: 90%;
      border-radius: .5em;
    }
  }

  &__searchIcon {
    display: flex;
    font-size: 1.5em;
    cursor: pointer;
    border: none;
    padding: .2em;
    background: none;

    > svg {
      display: flex;
      margin: auto;
      color: $main-color;
      padding: 0 .3em;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-top: 1em;
    max-height: 60vh;
    overflow-y: scroll;

    &.others {
      max-height: unset;
      overflow-y: unset;
      background-color: $light-blue-background;
    }

    &__empty {
      display: flex;
      margin: 2em auto;
      font-weight: bolder;
    }

    &__count {
      color: $black;
      margin: 1em auto;
      padding: 0.5em;
      background-color: $blue-medium;
      border-radius: 0.5em;
    }

    &__create {
      @extend .btn-success;
      background: $success!important;
      color: $white!important;
      margin: .5em 1em;
      font-weight: bolder;
    }

    &__more {
      @extend .btn;
      margin : 0 1em;
    }
  }

  &__actions {
    display: flex;
    margin: .5em;

    &__all, &__more, &__less {
      @extend .btn;
      display: flex;
      flex: 1;
      justify-content: center;
      font-weight: bolder;
      font-size: .9em;
      margin : .5em;
      padding: 1em;
    }
  }

  &__error {
    @extend .error;
    font-weight: bolder;
    display: flex;
    margin: 1em auto;
  }
}

.giid-list {
  @include giid-list;
}

