@import 'base';
@import 'constant';

@mixin need-teaser {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 1em 1em;
  padding: 0.5em;
  background-color: $white;
  border-radius: 0.5em;
  cursor: pointer;

  &.with-profile {
    background-color: $soft-green;
  }

  &.with-selected-profile {
    background-color: $bg-green;
  }

  &.with-proposed-profile {
    background-color: $gray;
  }

  &.blank {
    align-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: $white;
    width: 85vw;
    max-width: 560px;
    height: 120px;
    padding: .5em;
    margin: 1em;

    &__count {
      margin: 1em auto;
      padding: 0.5em;
      background-color: $blue-medium;
      border-radius: 0.5em;
      height: 20px;
      width: 150px;
    }

    &__title, &__client, &__period, &__total-needs {
      background-color: $gray;
      width: 65%;
      max-width: 300px;
      height: 10px;
      margin: .5em;
    }

    &__client {
      width: 75%;
      max-width: 400px;
      background-color: $blue-medium;
    }

    &__period {
      margin-left: 1em;
      width: 45%;
      max-width: 200px;
    }

    &__total-needs {
      width: 15%;
      max-width: 100px;
      background-color: $blue-medium;
      margin: 0 2em .5em auto;
    }
  }

  &__loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    > svg {
      @extend .fa-spin;
      font-size: 5em;
    }
  }

  &__error {
    @extend .error;
    display: flex;
    margin: 2em auto;
    font-weight: bolder;
  }

  &__info {
    color: $black;
  }

  &__giid-title {
    color: $black;
    margin: .5em 0;
    font-weight: bolder;
  }

  &__title {
    display:flex;
    justify-content:space-between;
    color: $main-color;
    font-weight: bolder;
    margin-bottom: .8em;
  }

  &__has-profile, &__date, &__skills {
    font-size: 0.9em;
    padding-left:1em;
    margin-bottom: 0.4em;
  }

  &__has-profile {
    font-weight: bold;
    font-style: italic;
    padding-left:0.5em;
    margin-bottom: 0.4em;
  }

  &__has-accepted-profile {
    display:flex;
    color: $main-color;
    margin: 0.5em;
    font-weight: bold;
    font-style: italic;
  }

  &__proposals {
    margin-left: 0.8em;
    position: relative;

    > svg {
      color: #daa520;
      font-size: 2em;
      margin-top: 0.2em;
    }

    > span {
      padding: 0.15em 0.3em;
      background-color: $danger;
      color: $white;
      font-weight: bolder;
      border-radius: 50%;
      position: absolute;
      right: 0.1em;
    }
  }
}

.need-teaser {
  @include need-teaser;
}

