@import 'constant';

@mixin addToHomeScreen {
  margin-top: 1em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: solid 1px $main-color;

  > img {
    width: 40px;
    height: 40px;
    margin: 1em;
  }

  > svg {
    margin: 1em;
  }
}

.addToHomeScreen {
  @include addToHomeScreen;
}