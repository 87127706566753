@mixin offers {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;

  &__info {
    &__label {
      font-weight: bolder;
      margin: 1em;
      text-align: center;
      color: $black;
    }

    &__need-resource {
      margin: 1em;
      font-weight: bolder;
      color: $blue-dark;
      > * {
        margin: 0 0.5em;
      }
    }
  }

  &__list__empty {
    margin: 1em;
    font-weight: bolder;
    color: $black;
    background-color: $warning;
    padding: 1em 0.5em;
  }
}
.offers {
 @include offers;
}